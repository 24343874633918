import RevealOnScroll from "./RevealOnScroll";
import RevealOnScrollOnce from "./RevealOnScrollOnce";
import TypingEffect from "./TypingEffect";

const Dahu3 = () => {
  return (
    <section
      className="relative self-stretch overflow-hidden
    [background:linear-gradient(180deg,_rgba(166,_166,_255,_0.2),_rgba(6,_6,_26,_0)),_#101040]
    border-t-[1px] border-solid border-stroke-stroke-01 box-border
    px-[100px] py-[140px] overflow-hidden
    mq1024:px-[16px] mq1024:py-[80px]
    "
      data-scroll-to="aboutUs"
    >
      <img
        className="absolute top-[300px] left-[600px] w-[973px] h-[547px] object-cover
        mq1024:top-[450px]"
        alt=""
        src="/imgs/bgimg@2x.png"
      />

    <div className="flex flex-row items-center justify-center">
      <section
          className="flex flex-row items-center justify-center gap-[40px] self-stretch w-[1240px]
                    text-left text-base text-cornflowerblue-100 font-plus-jakarta-sans
                    mq1024:w-full mq1024:flex-col mq1024:justify-center"
        >
          <div
            className="
              flex flex-col max-w-full gap-[36px] 
              w-[50%] h-[100%]
              mq750:gap-[18px] mq750:w-auto mq750:h-auto
              mq1024:w-full mq1024:h-full mq1024:ml-0
              mq1250:ml-[50px] 
              ">
            <RevealOnScroll className="hidden_text shrink-0 h-5 relative uppercase font-semibold inline-block">
              Powerful Artificial Intelligence
            </RevealOnScroll>

            <RevealOnScroll className="hidden_text m-0 relative text-25xl tracking-[-0.02em] leading-[57px] font-normal font-inherit text-surface-01 inline-block max-w-full z-[1] mq450:text-7xl mq450:leading-[34px] mq1050:text-16xl mq1050:leading-[46px]
                        mq1250:text-[40px]">
              <p className="m-0">Dahu: Effortless Entry into Crypto World </p>
            </RevealOnScroll>

            <RevealOnScroll className="hidden_text flex flex-row items-start justify-start relative max-w-full text-3xl text-surface-02">
              <h3 className="m-0 relative text-inherit tracking-[0.5px] leading-[180%] font-light font-inherit inline-block shrink-0 max-w-full z-[1] mq450:text-lg mq450:leading-[32px]
              mq1250:text-[18px]">
                Dahu is an intelligent and helpful AI companion for your trading experience. 
                As a digital companion, Dahu adapts its recommendations to align with each user's unique
                trading style and objectives, as well as market changes, thereby
                acting as a continuous support system for informed decisions.
              </h3>
            </RevealOnScroll>
          </div>


          <RevealOnScrollOnce
            className="hidden_text flex justify-center text-black w-[50%] box-border text-surface-01
                          mq1024:w-full
                          mq500:hidden"
          >
            <div className="relative rounded-3xl bg-gray-500 w-[289px] h-[582px] w-min-[289px] h-min-[582px]">
              {/* Buttons top */}
              <div className="absolute top-[15px] left-[15px]">
                <div className="flex flex-row items-start justify-start py-0 pr-0.5 pl-0 gap-[7px]">
                  <div className="h-[15px] w-[15px] relative rounded-[50%] bg-red" />
                  <div className="h-[15px] w-[15px] relative rounded-[50%] bg-yellow" />
                  <div className="h-[15px] w-[15px] relative rounded-[50%] bg-green-2" />
                </div>
              </div>

              {/* Button at the bottom */}
              <div className="absolute bottom-[25px] left-[15px] w-[259px]">
                <div className="self-stretch flex flex-row items-start justify-start py-0 pr-px pl-0.5">
                  <button className="in_depth_button pt-4 px-4 pb-[18px] bg-[transparent] flex-1 rounded-sm [background:linear-gradient(194.47deg,_#8975ff,_#5646ff)] shadow-[0px_6px_20px_rgba(40,_25,_132,_0.5)] flex flex-row items-center justify-center whitespace-nowrap border-[1px] border-solid border-mediumpurple hover:bg-slateblue-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-slateblue-100">
                    <div className="h-4 flex-1 relative text-base tracking-[0.01em] leading-[100%] font-semibold font-plus-jakarta-sans text-surface-01 text-center inline-block">
                      In-Depth Analytics
                    </div>
                  </button>
                </div>
              </div>

              {/* James Response */}
              <div className="absolute top-[200px] left-[-100px] w-[270px] my-0 mx-[!important] rounded-2xl [background:linear-gradient(163.46deg,_#6161d9,_#9d61d9)] [backdrop-filter:blur(12px)] flex flex-col items-center justify-start pt-3.5 px-3.5 pb-4 box-border gap-[4px] z-[1]">
                <b className="self-stretch h-[30px] relative leading-[30px] inline-block">
                  James Smith
                </b>
                <div
                  id="dial2"
                  className="self-stretch h-[90px] relative tracking-[0.02em] leading-[24px] text-lavender-500 whitespace-pre-wrap inline-block"
                >
                  <TypingEffect text="Hey Dahu! That’s interesting." />
                  <TypingEffect text="Can you, please, give me" />
                  <TypingEffect text="more details and keep me" />
                  <TypingEffect text="updated?" />
                </div>
              </div>

              {/* Dahu Response 1 */}
              <div className="absolute top-[50px] right-[-100px] w-[242px] rounded-2xl [background:linear-gradient(269.99deg,_rgba(0,_0,_0,_0.5),_rgba(102,_102,_102,_0)),_radial-gradient(50%_50%_at_50%_50%,_rgba(35,_35,_54,_0.7),_rgba(35,_35,_54,_0.7)_0.01%,_rgba(35,_35,_54,_0.1))] [backdrop-filter:blur(12px)] flex flex-col items-center justify-start pt-3.5 px-3.5 pb-4 gap-[4px] z-[2]">
                <b className="self-stretch h-6 relative leading-[30px] flex items-center shrink-0">
                  Dahu
                </b>
                <div
                  id="dial1"
                  className="self-stretch h-[130px] relative tracking-[0.02em] leading-[26px] text-lavender-500 whitespace-pre-wrap inline-block"
                >
                  <TypingEffect text="Hey James! I noticed increased " />
                  <TypingEffect text="trading volumes and" />
                  <TypingEffect text="social media activities around" />
                  <TypingEffect text="ETH. Primary topic seems to be" />
                  <TypingEffect text="Dencun upgrade." />
                </div>
              </div>

              {/* Dahu Response 2 */}
              <div
                className="absolute bottom-[85px] right-[-100px] w-[270px] rounded-2xl [background:linear-gradient(269.99deg,_rgba(0,_0,_0,_0.5),_rgba(102,_102,_102,_0)),_radial-gradient(50%_50%_at_50%_50%,_rgba(35,_35,_54,_0.7),_rgba(35,_35,_54,_0.7)_0.01%,_rgba(35,_35,_54,_0.1))] 
                            [backdrop-filter:blur(12px)] flex flex-col items-center justify-start pt-3.5 px-3.5 pb-4 box-border gap-[4px] z-[2]"
              >
                <b className="self-stretch h-6 relative leading-[30px] flex items-center shrink-0">
                  Dahu
                </b>
                <div
                  id="dial3"
                  className="self-stretch h-[130px] relative tracking-[0.02em] leading-[26px] text-lavender-500 inline-block"
                >
                  <TypingEffect text="" special />
                  <TypingEffect text="around Dencun. Happy to set-" />
                  <TypingEffect text="up price alert for the next week" />
                  <TypingEffect text="to keep you informed on price" />
                  <TypingEffect text="changes, please, confirm." />
                </div>
              </div>
            </div>
          </RevealOnScrollOnce>

{/* 
          <RevealOnScrollOnce
            className="hidden_text w-[50%] h-[100%] flex flex-row items-center justify-center
            mq750:w-auto mq750:h-auto mq750:m-0 mq500:hidden">
            <img
              className="h-[450px] z-[10] shadow-xl"
              alt=""
              src="/imgs_add/Wallet4.png"
            />
          </RevealOnScrollOnce> */}
        </section>
      </div>
    </section>
  );
};

export default Dahu3;
