
import React, { useState, useEffect } from 'react';

function Background() {

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [curPosition, setCurPosition] = useState({ x: 0, y: 0 });

  const interBubble = document.querySelector('.interactive');

  useEffect(() => {

    const updateMousePosition = e => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const move = () => {
      setCurPosition({
        x: (curPosition.x + (mousePosition.x - curPosition.x) / 10),
        y: (curPosition.y + (mousePosition.y - curPosition.y) / 20)
      });
      
      if (interBubble !== null) {
        interBubble.style.transform = `translate(${Math.round(curPosition.x)}px, ${Math.round(curPosition.y)}px)`;
      }

    };

    window.addEventListener('mousemove', updateMousePosition);

    requestAnimationFrame(move);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition); // Cleanup function to remove the event listener when the component unmounts
    };
  }, [mousePosition]); // Empty dependency array means this effect runs only once on mount


  return (
    <div className="gradient-bg">
      
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
            <feColorMatrix in="blur" mode="matrix" values="
          1 0 0 0 0
          0 1 0 0 0
          0 0 1 0 0
          0 0 0 18 -8"
              result='goo' />
            <feBlend in2="goo" in="SourceGraphic" />
          </filter>

        </defs>
      </svg>

      <div className="gradients-container">
        <div className='g1'></div>
         <div className='g2'></div>
        <div className='g3'></div>
        <div className='g4'></div>
        <div className='g5'></div>
        <div className='interactive'></div>
      </div>
    </div>
  );
}

export default Background;
