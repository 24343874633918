import { useMemo } from "react";

const CoreTeamMember = ({ role, photo, name, linkedin, propColor }) => {
  const roleStyle = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  const linkedinUrl = `https://www.linkedin.com/in/${linkedin}`;
  const photoUrl = `/imgs_team/${photo}`;

  return (
    <div
      className="
    h-[424px] w-[260px] p-[30px]
    border-[2px] border-solid border-cornflowerblue-200 rounded-11xl 
    [background:linear-gradient(180deg,_#fafaff,_#f5f5ff)]
    text-left text-9xl text-stroke-stroke-06 font-plus-jakarta-sans
    overflow-hidden
    "
    >
      <div
        className="
      flex flex-col items-start justify-center gap-[24px]
      "
      >
        <div>
          <button className="cursor-default [border:none] p-2 bg-lavender-200 w-[210px] rounded-11xl flex flex-row items-center justify-center box-border whitespace-nowrap ">
            <div
              className="h-5 w-[200px] relative text-base font-semibold font-plus-jakarta-sans text-mediumslateblue-200 text-center inline-block"
              style={roleStyle}
            >
              {role}
            </div>
          </button>
        </div>

        <div
          className="h-[220px] w-[260px] rounded-xl border-[2px] border-solid border-cornflowerblue-200 rounded-11xl">
          
          <img
            className="w-full h-full object-cover rounded-xl box-border overflow-hidden shrink-0"
            alt=""
            src={photoUrl}
          />
        </div>

        <div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <h2 className="m-0 text-[24px] self-stretch h-10 relative tracking-[-0.01em] leading-[50px] font-medium font-inherit flex items-center shrink-0 mq450:text-3xl mq450:leading-[40px]">
              {name}
            </h2>

            <div className="flex flew-row items-center justify-start mt-[20px] cursor-pointer">
              <img src="/imgs/linkedin.svg" 
              onClick={() => window.open(linkedinUrl, "_blank")}
              />
              <a className="ml-[10px] text-base text-blue-700 tracking-[0.5px] leading-[150%] inline-block visited:text-blue-700
              [text-decoration:none]"
              href={linkedinUrl}  target="_blank">
                {linkedin}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreTeamMember;
