import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./styles/global.css";
import "./styles/global_extra.css";
import "./styles/featuredin.css";
import "./styles/styles_bg.scss";
import "./styles/team_styles.scss";
import "./styles/menu.css";
import ReactGA from "react-ga4";

ReactGA.initialize("G-D6WNY97TJE");
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
