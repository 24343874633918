import RevealOnScroll from "./RevealOnScroll";
import RevealOnScrollOnce from "./RevealOnScrollOnce";

const CEX2 = () => {
  return (
    <section
      className="relative self-stretch overflow-hidden
    self-stretch [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.3)_67.94%,_rgba(199,_199,_255,_0.3)_98.26%)]
    border-t-[1px] box-border border-solid border-stroke-stroke-02
    px-[100px] py-[140px]
    overflow-hidden
    mq1024:px-[16px] mq1024:py-[80px] mq1024:h-full

    "
    >
      <div className="flex flex-row items-center justify-center">
        <section
          className=" 
        flex flex-row items-center justify-center gap-[40px] self-stretch
        w-[1240px]
        mq750:flex-col-reverse mq750:justify-center
        text-left text-base text-cornflowerblue-100 font-plus-jakarta-sans"
        >
          <RevealOnScrollOnce className="hidden_text w-min-[50%] h-[100%] mr-auto flex flex-row items-end justify-start
          mq750:m-0 mq500:hidden">
            <img
              className="h-[350px] z-[10] mq1275:flex-wrap mq1024:h-[250px] mq1250:h-[300px]"
              alt=""
              src="/imgs_add/TradeProDark.png"
            />
          </RevealOnScrollOnce>

          <div
            className="
              flex flex-col justify-center max-w-full gap-[36px] 
              text-base text-text-secondary font-plus-jakarta-sans
              w-[50%] h-[100%]
              mq750:gap-[18px] mq750:w-full

              "
          >
            <RevealOnScroll className="hidden_text self-stretch h-5 relative uppercase font-semibold inline-block">
              Foundation unlike any other
            </RevealOnScroll>

            <RevealOnScroll className="hidden_text m-0 self-stretch relative text-25xl tracking-[-0.02em] leading-[57px] font-medium font-inherit text-stroke-stroke-06 inline-block shrink-0 mq450:text-7xl mq450:leading-[34px] mq1050:text-16xl mq1050:leading-[46px]">
              Next Generation Platform
            </RevealOnScroll>

            <RevealOnScroll className="hidden_text mb-0 relative text-3xl tracking-[0.5px] leading-[180%] font-normal font-inherit text-text-body-copy inline-block mq450:text-lg mq450:leading-[32px]
            mq1250:text-[18px]">
              <p className="m-0">
              AlpinX Platform serves as the Foundation for AI companion and AlpinX's
                ecosystem. The platform is built with a focus on simplicity and
                compliance, designed to create a frictionless entry point into
                the digital cryptocurrency world.
              </p>
            </RevealOnScroll>
          </div>
        </section>
      </div>
    </section>
  );
};

export default CEX2;
