import React, { useEffect, useRef, useState } from 'react';

const RevealOnScroll = ({ children, className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once visible
        }
      });
    });

    observer.observe(domRef.current);
    return () => observer.disconnect(); // Clean up
  }, []);

  return (
    <div
      className={`${className} ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {children}
    </div>
  );
};

export default RevealOnScroll;
