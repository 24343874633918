import React from "react";

const TypingEffect = ({ text, special }) => {
  if (special) {
    return (
      <div className="typing-effect">
        Sure {" "}
        <a
          className="visited:text-blue-500"
          target="_blank"
          href="https://www.coindesk.com/tech/2024/02/28/what-to-expect-from-ethereums-cancun-deneb-upgrade/"
        >
          here
        </a>
        {" "} is a good article
      </div>
    );
  } else {
    return <div className="typing-effect">{text}</div>;
  }
};

export default TypingEffect;
