import Roadmap_Text from "./Roadmap_Text";
import Roadmap_Circles from "./Roadmap_Circles";
import RevealOnScroll from "./RevealOnScroll";
import RevealOnScrollOnce from "./RevealOnScrollOnce";
import { SeparatorHorizontal } from "lucide-react";

const ImpRoad = () => {
  const connector_line = () => {
    return (
      <div className="border-t-[1px] w-full min-w-[10px] border-dashed border-white" />
    );
  };

  const connector_line_small = () => {
    return (
      <div className="border-t-[1px] w-full min-w-[70px] border-dashed border-white" />
    );
  };

  const center_console = () => {
    return (
      <div className="w-[70px] flex flex-col items-center justify-start gap-[0px] max-w-full">
        <div
          className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white
      text-9xl text-slateblue-200"
        >
          00
        </div>

        <div className="w-[0px] h-[180px] border-l-[1px] border-dotted border-white" />

        <div
          className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white
      text-9xl text-slateblue-200"
        >
          01
        </div>

        <div className="w-[0px] h-[180px] border-l-[1px] border-dotted border-white" />

        <div
          className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white
      text-9xl text-slateblue-200"
        >
          02
        </div>

        <div className="w-[0px] h-[180px] border-l-[1px] border-dotted border-white" />

        <div
          className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white
      text-9xl text-slateblue-200"
        >
          03
        </div>

        <div className="w-[0px] h-[280px] border-l-[1px] border-dotted border-white" />

        <div
          className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white
      text-9xl text-slateblue-200">
      </div>
      </div>
    );
  };


  
  const center_console_small = () => {
    return (
      <div className="w-[70px] flex flex-col items-center justify-start gap-[0px] max-w-full">
        <div className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white text-9xl text-slateblue-200">
          00
        </div>
        <div className="w-[0px] h-[240px] border-l-[1px] border-dotted border-white" />
        <div className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white text-9xl text-slateblue-200">
          01
        </div>

        <div className="w-[0px] h-[275px] border-l-[1px] border-dotted border-white" />

        <div
          className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white text-9xl text-slateblue-200"
        >
          02
        </div>

        <div className="w-[0px] h-[270px] border-l-[1px] border-dotted border-white" />

        <div
          className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white
      text-9xl text-slateblue-200"
        >
          03
        </div>

        <div className="w-[0px] h-[340px] border-l-[1px] border-dotted border-white" />

        <div
          className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white
      text-9xl text-slateblue-200
      "
        ></div>
      </div>
    );
  };
  


  const milestone_block = (txt1, txt2, txt3, txt4, txt5, txt6, txt7) => {
    return (
      <RevealOnScroll className="hidden_text flex flex-row self-stretch w-full items-top justify-center gap-0">
      <div className="flex flex-col items-start justify-start gap-[25px] pb-[40px]">
        <div className="self-stretch flex flex-row items-center justify-start">
          <button className="py-1 px-6 bg-mediumslateblue-600 w-[410px] rounded-21xl box-border flex flex-row items-center justify-start gap-[16px] whitespace-nowrap max-w-full border-[1px] border-solid border-surface-01 
          mq768:w-[350px]">
            <img
              className="h-[30px] w-[30px] relative"
              alt=""
              src="/imgs/vector-2.svg"
            />
            <div className="h-full w-full relative text-xl leading-[57px] font-semibold font-plus-jakarta-sans text-surface-01 text-left inline-block
            mq768:text-[18px]">
            <span className="uppercase">{txt1}{` `}</span>{txt2}
              </div>
          </button>
        </div>
        <Roadmap_Text
          className="mq1024:text-[18px] mq768:text-[16px]"
          alpinXUtilityTokenGoLive={txt3}
          stateOfTheArtMostUserFrie={txt4}
          q32024={txt5}
          propBackgroundColor={txt6}        />
      </div>

      <div className="flex items-center justify-center h-[70px]
       mq768:hidden"> 
        {connector_line_small()}
      </div>

      <div className="flex flex-col items-center justify-top gap-0 w-[70px]
       mq768:hidden">
      
        <div className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col items-center justify-center bg-white text-9xl text-slateblue-200">
          {txt7}
        </div>
        <div className="grow w-[0px] h-[285px] border-l-[1px] border-dotted border-white" />

      </div>
    </RevealOnScroll>


    )};






    

  return (
    <section
      className="self-stretch flex flex-row items-center justify-center pt-[100px] px-[100px] box-border text-center text-base text-cornflowerblue-100 font-plus-jakarta-sans mq450:box-border mq1050:box-border
    [background:linear-gradient(#101040,_#101040),_linear-gradient(180deg,_rgba(252,_253,_253,_0.2),_rgba(185,_185,_255,_0.2)),_#fff] border-t-[1px] border-solid border-stroke-stroke-01
    overflow-hidden h-[1515px]
    mq1024:px-[16px] mq1024:pt-[80px] mq1024:pt-[10px] mq1024:h-[1780px]
    mq1250:px-[50px]"
    
      data-scroll-to="Roadmap"
    >
      <div
        className="relative self-stretch flex items-center justify-center w-full overflow-hidden
      mq1024:h-full"
      >
        {/* Background Image */}
        <div className="absolute top-[567px] self-stretch h-[843px] mq1024:top-[860px]">
          <img
            className="h-full mx-[!important]"
            alt=""
            src="/imgs/bgimg-1@2x.png"
          />
        </div>

        {/* Main Container */}
        <div
          className=" max-w-[1240px] h-full flex-1  overflow-hidden flex flex-col items-center justify-start w-full
            gap-[64px] mq450:gap-[16px] mq450:pt-[27px] mq450:box-border mq750:gap-[32px] mq1050:h-auto mq1050:pt-[42px] mq1050:box-border
            mq1024:hidden
            z-[1]"
        >
          {/* Title of the Roadmap */}
          <div className="w-full flex flex-col items-center justify-start  gap-[24px] box-border max-w-full shrink-0 mq750:gap-[20px]">
            <RevealOnScroll className="hidden_text w-full flex flex-col items-center justify-start max-w-full">
              <div className="self-stretch h-5 relative uppercase font-semibold inline-block
              mq768:text-[16px]">
                First phase on the strategic roadmap
              </div>
            </RevealOnScroll>
            <RevealOnScroll className="hidden_text m-0 w-full h-[57px] relative text-37xl tracking-[-0.02em] leading-[57px] font-medium font-inherit text-surface-01 inline-block max-w-full 
            mq450:text-15xl mq450:leading-[34px] mq1050:text-26xl mq1050:leading-[46px]
            mq768:text-15xl">
              Roadmap
            </RevealOnScroll>
            <RevealOnScroll className="hidden_text m-0 self-stretch h-20 relative text-3xl tracking-[0.5px] leading-[180%] font-normal font-inherit text-stroke-stroke-02 inline-block mq450:text-lg mq450:leading-[32px]">
              <p className="m-0">{`Named after highest peaks of Swiss Alps, `}</p>
              <p className="m-0">the project has 4 distinct phases.</p>
            </RevealOnScroll>
          </div>

          {/* Roadmap itself */}

          <div className="flex flex-row items-top justify-center gap-[0px] w-full self-stretch">
            <div className="w-[580px] flex flex-col items-start justify-start gap-[217px] max-w-full mq450:gap-[45px] mq750:gap-[90px]">
              <RevealOnScroll className="hidden_text flex flex-col items-start justify-start gap-[25px] w-full">
                <div className="self-stretch flex flex-row items-center justify-start">
                  <button className="py-1 px-6 bg-mediumslateblue-600 w-[410px] rounded-21xl box-border flex flex-row items-center justify-start gap-[16px] whitespace-nowrap max-w-full border-[1px] border-solid border-surface-01 ">
                    <img
                      className="h-[30px] w-[30px] relative"
                      alt=""
                      src="/imgs/vector-2.svg"
                    />
                    <div className="h-full w-full relative text-xl leading-[57px] font-semibold font-plus-jakarta-sans text-surface-01 text-left inline-block">
                      <span className="uppercase">{`Taschhorn. `}</span>
                      Foundation
                    </div>
                  </button>

                  {connector_line()}
                </div>
                <Roadmap_Text
                  className="mq1024:text-[18px]"
                  alpinXUtilityTokenGoLive="AlpinX utility token go-live"
                  stateOfTheArtMostUserFrie="State-of-the-art most user-friendly Swiss-based Cryptocurency Platform (upon regulatory clearance) "
                  q32024="2024"
                  propBackgroundColor="#39bf7c"
                />
              </RevealOnScroll>

              <RevealOnScroll className="hidden_text flex flex-col items-start justify-start gap-[25px] w-full">
                <div className="self-stretch flex flex-row items-center justify-start max-w-full mq750:flex-wrap">
                  <button className="py-1 px-6 bg-mediumslateblue-600 w-[410px] rounded-21xl box-border flex flex-row items-center justify-start gap-[16px] whitespace-nowrap max-w-full border-[1px] border-solid border-surface-01 ">
                    <img
                      className="h-[30px] w-[30px] relative"
                      alt=""
                      src="/imgs/vector-2.svg"
                    />
                    <div className="h-full w-full relative text-xl leading-[57px] font-semibold font-plus-jakarta-sans text-surface-01 text-left inline-block">
                      <span className="uppercase">{`LYSKAMM. `}</span>
                      Rapid Expansion
                    </div>
                  </button>
                  {connector_line()}
                </div>

                <Roadmap_Text
                  alpinXUtilityTokenGoLive="Full Self-Custody Exchange for ultimate user control over digital assets"
                  stateOfTheArtMostUserFrie="Rapid ecosystem expansion across regions"
                  q32024="2025 +"
                  propBackgroundColor="#314dd8"
                />
              </RevealOnScroll>
            </div>

            {/* Middle Section */}
            {center_console()}

            <div className="w-[580px] flex flex-col items-start justify-start gap-[0px] max-w-full mq450:gap-[45px] mq750:gap-[90px]">
              <RevealOnScroll className="hidden_text flex flex-col items-start justify-start gap-[25px] w-full mt-[251px]">
                <div className="self-stretch w-full flex flex-row items-center justify-end">
                  {connector_line()}
                  <button
                    className="py-1 px-6 bg-mediumslateblue-600 w-[400px] rounded-21xl box-border flex flex-row items-center justify-start gap-[16px] whitespace-nowrap 
                                         border-[1px] border-solid border-surface-01 "
                  >
                    <img
                      className="h-[30px] w-[30px] relative"
                      alt=""
                      src="/imgs/vector-2.svg"
                    />
                    <div className="h-full w-full relative text-xl leading-[57px] font-semibold font-plus-jakarta-sans text-surface-01 text-left inline-block">
                      <span className="uppercase">{`WEISSHORN. `}</span>Early
                      Innovation
                    </div>
                  </button>
                </div>

                <div className="w-full flex flex-col items-end justify-center max-w-full">
                  <div className="w-[390px]">
                    <Roadmap_Text
                      alpinXUtilityTokenGoLive="AI companion «Dahu» for totally new user experience"
                      stateOfTheArtMostUserFrie="Cryptocurrency platform growth and development"
                      q32024="2025"
                      propBackgroundColor="#1698D9"
                    />
                  </div>
                </div>
              </RevealOnScroll>

              <RevealOnScroll className="hidden_text flex flex-col items-start justify-start gap-[25px] w-full mt-[215px]">
                <div className="self-stretch flex flex-row items-center justify-start w-full mq750:flex-wrap">
                  {connector_line()}
                  <div className="w-[390px]">
                    <button className="py-1 px-6 bg-mediumslateblue-600 w-[400px] rounded-21xl box-border flex flex-row items-center justify-start gap-[16px] whitespace-nowrap max-w-full border-[1px] border-solid border-surface-01 ">
                      <img
                        className="h-[30px] w-[30px] relative"
                        alt=""
                        src="/imgs/vector-2.svg"
                      />
                      <div className="h-full w-full relative text-xl leading-[57px] font-semibold font-plus-jakarta-sans text-surface-01 text-left inline-block">
                        <span className="uppercase">{`DOM. `}</span>Strategic
                        Growth
                      </div>
                    </button>
                  </div>
                </div>

                <div className="w-full flex flex-col items-end justify-start max-w-full">
                  <Roadmap_Text
                    alpinXUtilityTokenGoLive="Universal Wallet - most versatile cross-protocol digital assets wallet"
                    stateOfTheArtMostUserFrie="Preparation for next phase of Ecosystem growth"
                    q32024="2025 +"
                    propBackgroundColor="#4D19EB"
                  />
                </div>
              </RevealOnScroll>
            </div>
          </div>
        </div>

















        <div
          className="hidden max-w-[1240px] h-full flex-1  overflow-hidden flex flex-col items-center justify-start w-full
            gap-[64px] overflow-hidden 
            mq1024:flex
            mq1050:h-auto mq1050:box-border
            z-[1]">
          {/* Title of the Roadmap */}
          <div className="w-full flex flex-col items-center justify-start  gap-[24px] box-border max-w-full shrink-0 mq750:gap-[20px]">
            <RevealOnScroll className="hidden_text w-full flex flex-col items-center justify-start max-w-full">
              <div className="self-stretch h-5 relative uppercase font-semibold inline-block
              mq768:text-[16px]">
                First phase on the strategic roadmap
              </div>
            </RevealOnScroll>
            <RevealOnScroll className="hidden_text m-0 w-full h-[57px] relative text-37xl tracking-[-0.02em] leading-[57px] font-medium font-inherit text-surface-01 inline-block max-w-full mq450:text-15xl mq450:leading-[34px] mq1050:text-26xl mq1050:leading-[46px]
            mq768:text-[35px]">
              Roadmap
            </RevealOnScroll>
            <RevealOnScroll className="hidden_text m-0 self-stretch h-20 relative text-3xl tracking-[0.5px] leading-[180%] font-normal font-inherit text-stroke-stroke-02 inline-block mq450:text-lg mq450:leading-[32px]
            mq768:text-[18px]">
              <p className="m-0">{`Named after highest peaks of Swiss Alps, `}</p>
              <p className="m-0">the project has 4 distinct phases.</p>
            </RevealOnScroll>
          </div>

            
            
          {/* Roadmap itself */}
          <div className="flex flex-col items-top justify-center gap-0 w-full self-stretch">

            <div className="flex flex-col justify-center items-center width-auto ">

              <div>
                {milestone_block("Taschhorn.", "Foundation", "AlpinX utility token go-live",
                      "State-of-the-art most user-friendly Swiss-based Cryptocurency Platform (upon regulatory clearance)", "2024", "#39bf7c", "00")}
                {milestone_block("WEISSHORN.", "Early Innovation", "AI companion «Dahu» for totally new user experience",
                      "Cryptocurrency platform growth and development", "2025", "#1698D9", "01")}
                {milestone_block("LYSKAMM.", "Rapid Expansion", "Full Self-Custody Exchange for ultimate user control over digital assets",
                      "Rapid ecosystem expansion across regions", "2025+", "#314dd8", "02")}
                {milestone_block("DOM.", "Strategic Growth", "Universal Wallet - most versatile cross-protocol digital assets wallet",
                      "Preparation for next phase of Ecosystem growth", "2025+", "#4D19EB", "03")}
              </div>

              <div className="w-[550px] flex items-end justify-end 
               mq768:hidden">
                <div className="w-[70px] h-[70px] border-[1px] border-solid border-stroke-stroke-01 rounded-full flex flex-col bg-white text-9xl text-slateblue-200"/>
              </div>

            </div>
          </div>


        </div>
      </div>
    </section>
  );
};

export default ImpRoad;
