import { useCallback } from "react";
import Hamburger from "hamburger-react";

import { useState } from "react";
// import { Sun, Moon } from '/node_modules/lucide-react';
const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeNavbar = () => {
    setIsOpen(false);
  };

  const onAboutTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='aboutUs']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
      closeNavbar();
    }
  }, []);

  const onHomeClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='HeroHome']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
      closeNavbar();
    }
  }, []);

  const onRoadMapTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='Roadmap']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
      closeNavbar();
    }
  }, []);

  const onTeamTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='OurTeam']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
      closeNavbar();
    }
  }, []);

  const onContactsTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='ContactUs']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
      closeNavbar();
    }
  }, []);

  const openPdfFile = () => {
    const filePath = "/docs/AlpinX_WP.pdf";
    window.open(filePath, "_blank");
    closeNavbar();
  };

  const goToHomePage = () => {
    window.location.href = "/";
    closeNavbar();
  };

  return (
    <div className="fixed z-[100]  w-full">
      <section
        className="h-[94px] menu_down relative top-0 w-full my-0 mx-[!important] absolute top-[0px] right-[0px] left-[0px] flex flex-row items-center justify-center pt-0 px-0 box-border max-w-full
      mq1024:h-[80px]"
      >
        <header
          className="flex-1 bg-gray1 [backdrop-filter:blur(4px)] flex flex-row items-center justify-between h-full px-[100px] box-border top-[0] z-[99] sticky gap-[20px] max-w-full text-center text-base text-stroke-stroke-06 font-plus-jakarta-sans 
                    lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:pl-[25px] mq750:pr-[25px] mq750:box-border  "
        >
          {/* mq1024:px-[16px] mq1024:text-[14px] */}
          <img
            className="h-[44.4px] w-[120px] relative overflow-hidden shrink-0 object-contain"
            loading="lazy"
            alt=""
            src="/logo/logo_new_black_2.svg"
          />
          {/* <img
          className="hidden h-[44.4px] w-[120px] relative overflow-hidden shrink-0 object-contain mq1050:vissible mq1050:block mq1050:w-[50px]"
          loading="lazy"
          alt=""
          src="/imgs/alpinxlogo_sign.png"
        /> */}

          <div
            className="rounded-71xl bg-gray-700 flex flex-row items-center justify-start py-3.5 px-[30px] box-border gap-[48px] max-w-full mq750:gap-[24px] 
            mq1250:hidden
              mq1024:gap-[32px] mq1024:py-[13px] mq1024:px-[20px] mq1024:text-[14px]"
          >
            <div
              className="relative font-medium inline-block cursor-pointer"
              onClick={onHomeClick}
            >
              Home
            </div>
            <div
              className="relative font-medium inline-block cursor-pointer"
              onClick={onAboutTextClick}
            >
              About
            </div>
            <div
              className="relative font-medium inline-block cursor-pointer"
              onClick={onRoadMapTextClick}
            >
              Roadmap
            </div>
            <div
              className="relative font-medium inline-block whitespace-nowrap cursor-pointer"
              onClick={onTeamTextClick}
            >
              Our team
            </div>
            <div
              className="relative font-medium inline-block cursor-pointer"
              onClick={onContactsTextClick}
            >
              Contacts
            </div>
          </div>

          <div
            className="flex flex-row items-center justify-center gap-[8px] max-w-full
            mq1250:hidden"
          >
            <button
              className="cursor-pointer pt-3.5 px-7 pb-4 bg-gray1 w-[153px] rounded-11xl box-border overflow-hidden shrink-0 flex flex-row items-center justify-center border-[2px] border-solid border-stroke-stroke-06 hover:bg-gainsboro-200 hover:box-border hover:border-[2px] hover:border-solid hover:border-text-body-copy
            mq1024:px-[20px] mq1024:pt-[12px] mq1024:pb-[14px] mq1024:text-[14px] mq1024:font-normal mq1024:font-weight-[700] mq1024:font-line-height-[normal] 
            mq1024:h-[44px] mq1024:w-[147px]"
            onClick={openPdfFile}

            >
              <b
                className="h-5 w-[93px] relative text-base inline-block font-plus-jakarta-sans text-stroke-stroke-06 text-center"
              >
                Whitepaper
              </b>
            </button>
            <button
              className="cursor-pointer [border:none] pt-3.5 px-7 pb-4 bg-black rounded-11xl overflow-hidden flex items-center justify-center whitespace-nowrap 
                        hover:[box-shadow:0px_2px_20px_0px_rgba(113,_112,_255,_0.80)]
                        hover:[background:var(--Button-Styling-Primary-Background,_linear-gradient(353deg,_#7D6CE1_-5.69%,_#35F_-5.68%,_#7272FE_109.08%))]
                        mq1024:px-[20px] mq1024:pt-[12px] mq1024:pb-[14px] mq1024:text-[14px] mq1024:font-normal mq1024:font-weight-[500] mq1024:font-line-height-[normal] 
                        mq1024:h-[44px] mq1024:w-[147px]"
              onClick={onContactsTextClick}
            >
              <div
                className="h-5 w-[127px] relative text-base font-medium font-plus-jakarta-sans text-surface-01 text-center inline-block
                "
                
              >
                Join Community
              </div>
            </button>
          </div>
        </header>

        <div className="hidden fixed flex z-[110] w-[300px] top-0 right-0 items-start justify-end hidden mq1250:flex">
          <div className="mt-[25px] mr-[25px] z-[120] w-[48px]">
            <div className={isOpen ? "text-white" : "text-black"}>
              <Hamburger
                className="text-white"
                toggled={isOpen}
                toggle={setIsOpen}
              />
            </div>
          </div>

          <div
            className={
              isOpen
                ? "absolute z-[105] top-0 right-0 h-[100vh] w-full bg-[#101040] transition-all duration-300 ease-in-out"
                : "absolute z-[105] top-0 right-[-300px] h-[100vh] w-[300px] transition-all bg-[#101040] duration-300 ease-in-out"
            }
          >
            <div
              className="flex flex-col gap-[25px] py-[25px] mr-[90px] mt-[25px] ml-[30px] items-center
                            text-center text-base text-white font-plus-jakarta-sans font-medium cursor-pointer"
            >
              <div onClick={onHomeClick}>Home</div>
              <div onClick={onAboutTextClick}>About</div>
              <div onClick={onRoadMapTextClick}>Roadmap</div>
              <div onClick={onTeamTextClick}>Our team</div>
              <div onClick={onContactsTextClick}>Contacts</div>

              <button
                className="cursor-pointer pt-3.5 px-7 pb-4 bg-white w-[153px] rounded-11xl box-border overflow-hidden shrink-0 flex flex-row items-center justify-center 
                            border-[2px] border-solid border-stroke-stroke-06 hover:bg-white-100 hover:box-border hover:border-[2px] hover:border-solid hover:border-white"
              >
                <b
                  className="h-5 w-[93px] relative text-base inline-block font-plus-jakarta-sans text-stroke-stroke-06 text-center"
                  onClick={openPdfFile}
                >
                  Whitepaper
                </b>
              </button>

              <button
                className="cursor-pointer pt-3.5 pb-4 bg-white w-[180px] rounded-11xl box-border overflow-hidden shrink-0 flex flex-row items-center justify-center 
                hover:[box-shadow:0px_2px_20px_0px_rgba(113,_112,_255,_0.80)]
                hover:[background:var(--Button-Styling-Primary-Background,_linear-gradient(353deg,_#7D6CE1_-5.69%,_#35F_-5.68%,_#7272FE_109.08%))]
                hover:text-white"
                onClick={onContactsTextClick}

              >

                <b
                  className="h-5 w-full relative text-base inline-block font-plus-jakarta-sans text-center hover:text-white"
                  >
                  Join Community
                </b>
              </button>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NavBar;
