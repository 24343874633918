import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import CEX from "../components/CEX";
import CEX_2 from "../components/CEX_2";
import FullSelfCustody from "../components/FullSelfCustody";
import Wallet from "../components/Wallet_2";
import Dahu from "../components/Dahu_2";
import Dahu3 from "../components/Dahu_3";
import ImpRoad from "../components/ImpRoad";
import Team from "../components/Team";
import CoreTeam from "../components/CoreTeam";
import App from "../components/CoreTeamCompanies";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import Background from "../components/Background";
import FeaturedIn from "../components/FeaturedIn";

const AlpinXICOLanding = () => {
  return (
    <div>
      <div className="w-full relative overflow-hidden flex flex-col items-center justify-start tracking-[normal]">
        <NavBar />
        <Hero />
        <FeaturedIn />
        <Dahu3 />
        {/* <Dahu /> */}

        <CEX_2 />
        {/* <CEX /> */}
        {/* <FullSelfCustody /> */}
        {/* <Wallet /> */}
        <ImpRoad />
        <Team />
        <CoreTeam />
        {/* <App /> */}
        <ContactUs />
        <Footer />
        
      </div>
      <Background />
    </div>
  );
};

export default AlpinXICOLanding;
