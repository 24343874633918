import RevealOnScroll from "./RevealOnScroll";
import RevealOnScrollOnce from "./RevealOnScrollOnce";
import CoreTeamCompanies from "./CoreTeamCompanies";

const CoreTeam = () => {
  return (
    <section
      className="self-stretch [background:linear-gradient(180deg,_rgba(199,_199,_255,_0.6),_rgba(255,_255,_255,_0.6)_30%,_rgba(255,_255,_255,_0.6))] box-border overflow-hidden 
      border-t-[1px] border-solid border-stroke-stroke-01 box-border
    flex flex-col items-center justify-start 
    pt-[110px] pb-[140px]
    gap-[20px] max-w-full 
    text-center text-25xl text-stroke-stroke-06 font-plus-jakarta-sans 
    mq450:gap-[37px] mq450:pt-[71px] mq450:box-border mq750:gap-[73px]
    mq1024:pt-[80px] mq1024:pb-[80px] mq1024:min-h-0 mq1024:px-[16px]
    "
    >
      <div className="w-[1280px] flex flex-col items-end justify-start py-0 px-5 box-border gap-[80px] max-w-full mq450:gap-[20px] mq750:gap-[40px]
       mq1024:w-full mq1024:px-0 ">
        <div className="self-stretch flex flex-col items-center justify-start gap-[24px]">
          <RevealOnScroll className="hidden_text m-0 w-full relative text-inherit tracking-[-0.02em] leading-[57px] font-medium font-inherit inline-block max-w-full mq450:text-7xl mq450:leading-[34px] mq750:text-16xl mq750:leading-[46px]
          mq1024:h-auto">
            Core Team
          </RevealOnScroll>
          <RevealOnScroll className="hidden_text self-stretch relative text-lg tracking-[0.5px] leading-[160%] text-text-body-copy inline-block
          mq1024:h-auto">
            <p className="m-0">
            
              Our core team is a distinguished assembly of professionals,
              combining deep expertise <br />
              in both the crypto-economy and traditional financial sectors.<br />
              With seasoned experience in crypto companies like Voyager, and prominent roles in Fortune 500 firms including <br />
              Deutsche Bank, Citi, Bank of New York Mellon, Ernst & Young, Black Knight, and JP Morgan, <br />our team brings a wealth of knowledge and insight. 
            </p>
          </RevealOnScroll>
        </div>

      </div>

      <RevealOnScroll className="fadeDiv hidden_text flex flex-col items-center justify-center gap-[30px] self-stretch
      ">
        <CoreTeamCompanies />
      </RevealOnScroll>
    </section>
  );
};

export default CoreTeam;
