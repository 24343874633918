import RevealOnScroll from "./RevealOnScroll";

const FeaturedIn = () => {
  const featureBlock = (link, source, class_name) => {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <img src={source} alt="" className={class_name} />
      </a>
    );
  };

  return (
    <div className="featured_container">
      <RevealOnScroll className="hidden_text featured_title">AS FEATURED IN:</RevealOnScroll>
      <RevealOnScroll className="hidden_text featured_row">
        {featureBlock(
          "https://markets.businessinsider.com/news/currencies/alpinx-launches-its-crypto-exchange-with-ai-based-companion-called-dahu-1033216585",
          "featured_logos/BusinessInsider.png",
          "img_businessinder"
        )}
        {featureBlock(
          "https://www.binance.com/en/square/post/5952478695489",
          "featured_logos/BinanceSquare.png",
          "img_binance"
        )}
        {featureBlock(
          "https://coinmarketcap.com/community/articles/6603b09731a0255ebe127918/",
          "featured_logos/CoinMarketCapLogo.png",
          "img_coinmarketcap"
        )}
        {featureBlock(
          "https://techbullion.com/alpinx-launches-its-crypto-exchange-with-ai-based-companion-called-dahu/",
          "featured_logos/TechBullion.png",
          ""
        )}
        {featureBlock(
          "https://www.ethnews.com/alpinx-launches-its-crypto-exchange-with-ai-based-companion-called-dahu/",
          "featured_logos/EthNews.png",
          "img_ethnews"
        )}
      </RevealOnScroll>
      <RevealOnScroll className="hidden_text featured_row">

        {featureBlock(
          "https://paragraph.xyz/@web3_news/alpinx-launches-its-crypto-exchange-with-ai-based-companion-called-dahu",
          "featured_logos/Paragraph.png",
          ""
        )}
        {featureBlock(
          "https://www.benzinga.com/pressreleases/24/04/38056590/alpinx-launches-its-crypto-exchange-with-ai-based-companion-called-dahu",
          "featured_logos/Benzinga.png",
          "img_benzinga"
        )}
      </RevealOnScroll>
    </div>
  );
};

export default FeaturedIn;
