import React, { useState } from "react";
import axios from "axios";
import RevealOnScroll from "./RevealOnScroll";
import RevealOnScrollOnce from "./RevealOnScrollOnce";

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  // const [msgColor, setMsgColor] = useState("lime");
  let msgColor = "lime";

  // Email validation regex pattern
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  let msg = document.getElementById("msg");

  const handleMsgColor = () => {
    msg.style.color = msgColor;
    msg.style.opacity = 1;
    msg.classList.add("fade_msg_tag");

    const timer = setTimeout(() => {
      setError("");
      msg.classList.remove("fade_msg_tag");
      msg.style.opacity = 1;
    }, 5000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Validate email
    if (!validateEmail(email)) {
      msgColor = "red";
      handleMsgColor();
      setError("Please enter a valid email address.");
      return; // Stop the submission if the email is invalid
    }

    try {
      // Assuming you're sending a POST request
      const response = await axios.post(
        "https://h7cikd2ua3.execute-api.eu-central-2.amazonaws.com/v1/clients",
        JSON.stringify({ email })
      );
      msgColor = "lime";
      setError(email + " submitted. Thank you.");
    } catch (error) {
      console.error("ERROR ", error);
      msgColor = "red";
      setError("An error occurred. Please try again.");
    }

    setEmail("");
    handleMsgColor();
  };

  const icon = (src_path, link) => {
    return (
      <img
      className="h-[50px] w-[50px] relative"
      loading="lazy"
      alt=""
      src={src_path}
      onClick={() =>
        window.open(link, "_blank")
      }
    />
    );
  };


  return (
    <div
      className="self-stretch box-border overflow-hidden 
    flex flex-col items-center justify-start
    [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.6),_rgba(255,_255,_255,_0.6)_30%,_rgba(255,_255,_255,_0.6))]
    px-[100px]
    mq1024:px-[16px]"
      data-scroll-to="ContactUs"
    >
      <section
        className="w-[1238px] rounded-11xl [background:linear-gradient(180deg,_rgba(237,_237,_255,_0.2),_rgba(0,_0,_5,_0)_41.61%),_#101040] 
    box-border overflow-hidden 
    flex flex-col items-center justify-center gap-[5px]
     relative  min-h-[429px] max-w-full text-center 
    text-25xl text-lavender font-plus-jakarta-sans border-t-[1px] border-solid border-stroke-stroke-01 
    mq1275:w-[calc(100%_-_40px)] mq450:pt-[78px] mq450:pb-[78px] mq450:box-border
    mq768:min-h-[300px]"
      >
        <img
          className="mt-[-996px] ml-[-0.5px] mb-[-996px] w-[1499px] h-[843px] absolute my-0 mx-[!important] top-[703px] left-[-30px] object-cover"
          alt=""
          src="/imgs/image-64@2x.png"
        />

        <RevealOnScrollOnce
          className="hidden_text flex flex-col items-center justify-start gap-[50px] max-w-full z-[1] mq750:gap-[25px]
        mq768:w-full"
        >
          <div
            className="m-0 w-[514px] h-[57px] relative  
        text-inherit text-white tracking-[-0.02em] leading-[57px] font-normal font-inherit inline-block max-w-full 
        mq450:text-7xl mq450:leading-[34px] mq750:text-16xl mq750:leading-[46px]"
          >
            Join Our Community
          </div>

            <div className=" flex flex-row items-start justify-start gap-[32px] cursor-pointer  mq450:gap-[16px]">

              
              {icon("/imgs/telegram_75.svg", "https://t.me/alpinxcommunity")}
              {icon("/imgs/x_75.svg", "https://twitter.com/alpinx_io")}
              {icon("/imgs/discord_75.svg", "https://discord.com/invite/E5aS7B4dvC")}
              {icon("/imgs/linkedin_75.svg", "https://www.linkedin.com/company/alpinxdotio/")}
              {icon("/imgs/facebook_75.svg", "https://facebook.com/alpinx.exchange")}

              
            </div>

          <div
            className="flex flex-col items-center justify-start m-0
          mq768:w-[80%]"
          >
            <div
              className="
              flex flex-row items-center justify-center
              w-[519px] h-[50px] pl-[24px] pr-[7px] py-[6px]
              text-base inline-block font-plus-jakarta-sans text-surface-01 text-center
              bg-white overflow-hidden rounded-11xl 
              mq768:w-full
              "
            >
              <form
                onSubmit={handleSubmit}
                className="flex flex-row items-center justify-between w-full h-full"
              >
                <label className="w-full h-full pr-[7px] text-black">
                  <input
                    className="h-full w-full
                    font-plus-jakarta-sans
                    text-black text-left text-[18px] p-0
                    active:outline-none
                    focus:outline-none"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter your email"
                    name="email"
                    id="email"
                  />
                </label>

                <button
                  className="m-0 p-0 cursor-pointer [border:none] h-full w-[180px] rounded-11xl bg-surface-01 [background:linear-gradient(-6.97deg,_#7d6ce1,_#3355ff_0.01%,_#7272fe)]
                  overflow-hidden shrink-0 flex flex-row items-center justify-center box-border
                  mq768:w-[100px]"
                >
                  <b className="relative text-base inline-block font-plus-jakarta-sans text-surface-01 text-center">
                    Submit
                  </b>
                </button>
              </form>
            </div>
            <div
              className="m-0 h-[10px] w-full font-plus-jakarta-sans text-right text-[14px] pr-[20px]"
              id="msg"
            >
              {error && <p>{error}</p>}
            </div>
          </div>
        </RevealOnScrollOnce>
      </section>
    </div>
  );
};

export default ContactUs;
