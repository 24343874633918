import RevealOnScrollOnceFooter from "./RevealOnScrollOnceFooter";

const Footer = () => {

  const openPdfFile = () => {
    const filePath = "/docs/AlpinX_WP.pdf";
    window.open(filePath, "_blank");
  };

  const openPDFPrivacyPolicy = () => {
    const filePath = "/docs/PrivacyPolicy_Jan24.pdf";
    window.open(filePath, "_blank");
  };
  const openPDFTermsOfUse = () => {
    const filePath = "/docs/TermsOfUse_Jan24.pdf";
    window.open(filePath, "_blank");
  };

  const icon = (src_path, link) => {
    return (
      <img
      className="h-9 w-9 relative min-h-[36px]"
      loading="lazy"
      alt=""
      src={src_path}
      onClick={() =>
        window.open(link, "_blank")
      }
    />
    );
  };

  

  return (

    <RevealOnScrollOnceFooter className="footer_hidden self-stretch flex flex-row items-center justify-between pt-[60px] px-[100px] pb-[50px] box-border max-w-full gap-0 text-center text-base text-stroke-stroke-06 font-plus-jakarta-sans 
    mq450:pl-5 mq450:pr-5 mq450:box-border mq750:pl-[50px] mq750:pr-[50px] mq750:box-border mq1100:flex-wrap
    [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.6),_rgba(255,_255,_255,_0.6)_30%,_rgba(255,_255,_255,_0.6))]
    mq1024:px-[16px] mq1024:py-[40px]
    mq768:flex-wrap mq768:justify-center mq768:gap-[20px]">

      <div className=" flex flex-row items-start justify-start gap-[16px] cursor-pointer 
                    mq1024:gap-[8px] mq768:gap-[16px]">
        {icon("/imgs/telegram.svg", "https://t.me/alpinxcommunity")}
        {icon("/imgs/xcom.svg", "https://twitter.com/alpinx_io")}
        {icon("/imgs/discord.svg", "https://discord.com/invite/E5aS7B4dvC")}
        {icon("/imgs/linkedin.svg", "https://www.linkedin.com/company/alpinxdotio/")}
        {icon("/imgs/facebook.svg", "https://facebook.com/alpinx.exchange")}
      </div>
      
      
      <div className="flex flex-row items-center justify-center py-0 pl-0 text-[14px] box-border gap-[30px] mq450:gap-[17px]
      ml-[50px]
      mq1024:m-0">

        <a href="/" className="h-[25px] w-auto relative [text-decoration:none] font-medium inline-block visited:text-inherit"
        onClick={openPDFPrivacyPolicy}>
          Privacy Policy
        </a>

        <div className="h-[25px] w-[1px] relative border-l-[1px] border-[#8F8FB2] border-solid" />

        <a href="/" className="h-[25px] w-auto [text-decoration:none] relative font-medium inline-block visited:text-inherit"
        onClick={openPDFTermsOfUse}>
          Terms of use
        </a>

        <div className="h-[25px] w-[1px] relative border-l-[1px] border-[#8F8FB2] border-solid [text-decoration:none]
        
        " />
        <p className="h-[25px] w-auto relative font-medium inline-block visited:text-inherit mr-[10px] text-center
        mq1024:m-0">
          All rights reserved. AlpinX 2024
        </p>
      </div>

      {/* <button className="cursor-pointer pt-3.5 px-3.5 pb-4 bg-gray w-[180px] rounded-11xl box-border overflow-hidden shrink-0 flex flex-row items-center justify-center border-[2px] border-solid border-stroke-stroke-06 hover:bg-gainsboro hover:box-border hover:border-[2px] hover:border-solid hover:border-text-body-copy">
        <b className="h-5 w-[93px] relative text-base inline-block font-plus-jakarta-sans text-stroke-stroke-06 text-center"
        onClick={openPdfFile}>
          Whitepaper
        </b>
      </button> */}

      <button className="cursor-pointer pt-3.5 px-7 pb-4 bg-gray1 w-[153px] rounded-11xl box-border overflow-hidden shrink-0 flex flex-row items-center justify-center border-[2px] border-solid border-stroke-stroke-06 hover:bg-gainsboro-200 hover:box-border hover:border-[2px] hover:border-solid hover:border-text-body-copy
      mq768:hidden">
            <b className="h-5 w-[93px] relative text-base inline-block font-plus-jakarta-sans text-stroke-stroke-06 text-center"
            onClick={openPdfFile}>
              Whitepaper
            </b>
          </button>
    </RevealOnScrollOnceFooter>
  );
};

export default Footer;
