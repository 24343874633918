import React from "https://cdn.skypack.dev/react";
import ReactDOM from "https://cdn.skypack.dev/react-dom";

// const COLORS = [
//   "#bbf7d0",
//   "#99f6e4",
//   "#bfdbfe",
//   "#ddd6fe",
//   "#f5d0fe",
//   "#fed7aa",
//   "#fee2e2",
// ];
const TAGS = [
  "Deutsche Bank",
  "Citi",
  "JP Morgan",
  "Voyager",
  "Bank of New York Mellon",
  "Harvard University",
  "Ernst&Young",
  "MIT University",
  "Black Knight",
  "Silicon Valley Bank",
];
// const DURATION = 20000;
// const ROWS = 3;
// const TAGS_PER_ROW = 10;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
// const shuffle = (arr) => [...arr].sort(() => 0.5 - Math.random());

// const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
//   return (
//     <div
//       className="loop-slider"
//       style={{
//         "--duration": `${duration}ms`,
//         "--direction": reverse ? "reverse" : "normal",
//       }}
//     >
//       <div className="flex flex-row gap-[20px] inner">
//         {children}
//         {children}
//       </div>
//     </div>
//   );
// };

// const Tag = ({ text, reverse = false }) => (
//   <div
//     className="tag font-plus-jakarta-sans text-[28px] font-semibold leading-[160%] text-nowrap"
//     style={{ "--col": reverse ? "#333333" : "#5D69FF" }}
//   >
//     {text}
//   </div>
// );

const Part1 = () => {
  return (
    <>
    <div className="text-[#5D69FF]">{TAGS[0]}</div>
    <div className="">{TAGS[1]}</div>
    <div className="text-[#5D69FF]">{TAGS[2]}</div>
    <div className="">{TAGS[3]}</div>
    </>
  );
};

const Part2 = () => {
  return (
    <>
    <div className="text-[#5D69FF] ">{TAGS[4]}</div>
    <div className="">{TAGS[5]}</div>
    <div className="text-[#5D69FF]">{TAGS[6]}</div>
    <div className="">{TAGS[7]}</div>
    </>
  );
}

const Part3 = () => {
  return (
    <>
        <div>{TAGS[7]}</div>
        <div className="text-[#5D69FF]">{TAGS[8]}</div>
        <div>{TAGS[9]}</div>
        <div className="text-[#5D69FF]">{TAGS[3]}</div>
        <div>{TAGS[2]}</div>
        <div className="text-[#5D69FF]">{TAGS[0]}</div>
        <div>{TAGS[1]}</div>
        <div className="text-[#5D69FF]">{TAGS[4]}</div>
        <div>{TAGS[6]}</div>
        <div className="text-[#5D69FF]">{TAGS[5]}</div>
    </>
  );
}

const CoreTeamCompanies = () => (
  <div className="flex flex-col gap-[30px] mt-[20px] my-[0px] p-0 w-full text-[#333333] overflow-hidden shrink-0">
    <div
      className="flex flex-row gap-[20px] loop-slider font-plus-jakarta-sans text-[28px] 
                font-semibold leading-[160%] text-nowrap"
      style={{ "--duration": `40000ms`, "--direction": "normal" }}>
      <div className="flex flex-row gap-[50px] text-nowrap whitespace-nowrap">
        {Part1()}
        {Part1()}
        {Part1()}
        {Part1()}
      </div>
    </div>

    <div
      className="flex flex-row gap-[20px] loop-slider font-plus-jakarta-sans text-[28px] font-semibold leading-[160%] text-nowrap"
      style={{ "--duration": `20000ms`, "--direction": "reverse" }}
    >
      <div className="flex flex-row gap-[50px] text-nowrap whitespace-nowrap">
        {Part2()}
        {Part2()}
        {Part2()}
        {Part2()}
      </div>
    </div>

    <div
      className="flex flex-row gap-[20px] loop-slider font-plus-jakarta-sans text-[28px] font-semibold leading-[160%] text-nowrap"
      style={{ "--duration": `100000ms`, "--direction": "normal" }}
    >
      <div className="flex flex-row gap-[50px] text-nowrap whitespace-nowrap">
        {Part3()}
        {Part3()}
        {Part3()}
        {Part3()}  
      </div>
    </div>

    <div className="fade" />
  </div>
);

export default CoreTeamCompanies;
