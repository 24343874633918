import { useCallback } from "react";
import RevealOnScroll from "./RevealOnScroll";
import RevealOnScrollOnce from "./RevealOnScrollOnce";

const Hero = () => {
  const onAboutTextClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='aboutUs']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const openPdfFile = () => {
    const filePath = "/docs/AlpinX_WP.pdf";
    window.open(filePath, "_blank");
  };

  return (
    <div className="self-stretch">
      <section
        className="self-stretch flex flex-row items-center justify-center py-[180px] px-[100px] box-border max-w-full 
                   
                    text-left text-base text-text-body-copy font-plus-jakarta-sans mq450:pt-[76px] mq450:pb-[76px] mq450:box-border 
                    mq1250:h-[840px]
                    mq1024:h-[600px] mq1024:mt-[80px] mq1024:w-[1024px] mq1024:text-[14px]
                    mq1024:hidden"
        data-scroll-to="HeroHome"
      >
        {/* <div className="absolute flex justify-centent-center items-start w-[100%] h-[100%] top-0 left-0 items-center justify-center self-stretch bg-white m-0 p-0 z-[200]">
          <video
            className=" h-[600px] relative object-cover max-w-full mq1275:flex-1
                border-[1px] border-solid border-stroke-stroke-06 z-[10]
                bg-transparent
                "
            loading="lazy"
            autoPlay="1"
            muted
            playsInline
            alt=""
            src="video/Hero_Animation_1.mp4"
          />
        </div> */}

        <div className="h-[480px] w-[1240px] flex flex-row items-center justify-between py-0 pr-0 pl-0 box-border gap-[-34px] max-w-full mq450:h-auto ">
          <div className="flex flex-col items-start justify-start max-w-[calc(100%_-_266px)] shrink-0 ">
            <div className="w-full rounded-21xl flex flex-col items-start justify-center pt-20 pb-[60px] pr-20 pl-0 box-border gap-[40px] max-w-full mq750:gap-[20px] mq750:pt-[52px] mq750:pr-10 mq750:pb-[39px] mq750:box-border">
              <RevealOnScroll className="hidden_text self-stretch h-5 relative uppercase font-medium text-stroke-stroke-06 inline-block">
                Next Generation AI cryptocurrency ecosystem
              </RevealOnScroll>
              <div className="self-stretch flex flex-col items-start justify-center max-w-full text-[46px] mq1250:text-[46px]">
                <h1 className="m-0 w-full h-auto relative text-inherit leading-[58px] inline-block max-w-[103%] shrink-0 font-inherit mq450:text-16xl mq450:leading-[35px] mq1050:leading-[46px]">
                  <p className="m-0">
                    <RevealOnScroll className="hidden_text font-extrabold font-plus-jakarta-sans text-text-secondary">
                      AlpinX:{" "}
                      <snap className="text-text-body-copy font-semibold">
                       Radically Capable 
                      </snap>
                    </RevealOnScroll>
                  </p>
                  <RevealOnScroll className="hidden_text m-0 font-semibold">
                  AI Companion on your Crypto Journey
                  </RevealOnScroll>
                </h1>
              </div>
              <div className="self-stretch relative text-lg tracking-[0.5px] leading-[160%] inline-block">
                <h3
                  className="m-0 relative text-3xl tracking-[0.5px] leading-[180%] font-normal font-inherit text-text-body-copy inline-block mq450:text-lg mq450:leading-[32px]
              mq1250:text-[]"
                >
                  <RevealOnScroll className="hidden_text m-0">
                    Dive into the heart of the innovation with AlpinX - your
                    gateway to a seamless and secure crypto experience.
                    Our cutting-edge ecosystem and deep AI integration are designed to revolutionize the
                    way you trade, invest, and manage your digital assets.
                  </RevealOnScroll>
                </h3>
              </div>
            </div>
            <RevealOnScrollOnce className="hidden_text flex flex-row items-center justify-center gap-[8px] max-w-full mq450:flex-wrap">
              <button className="cursor-pointer [border:none] pt-3.5 px-7 pb-4 bg-stroke-stroke-06 rounded-11xl overflow-hidden flex flex-row items-center justify-center whitespace-nowrap
                              hover:[box-shadow:0px_2px_20px_0px_rgba(113,_112,_255,_0.80)]
                              hover:[background:var(--Button-Styling-Primary-Background,_linear-gradient(353deg,_#7D6CE1_-5.69%,_#35F_-5.68%,_#7272FE_109.08%))]
              "
              onClick={onAboutTextClick}
              >
                <div
                  className="h-5 w-[114px] relative text-base font-medium font-plus-jakarta-sans text-surface-01 text-center inline-block"
                >
                  Explore AlpinX
                </div>
              </button>
              <button className="cursor-pointer pt-3.5 px-7 pb-4 bg-gray1 w-[153px] rounded-11xl box-border overflow-hidden shrink-0 flex flex-row items-center justify-center border-[2px] border-solid border-stroke-stroke-06 hover:bg-gainsboro-200 hover:box-border hover:border-[2px] hover:border-solid hover:border-text-body-copy"
                  onClick={openPdfFile}
                  >
                <b
                  className="h-5 w-[93px] relative text-base inline-block font-plus-jakarta-sans text-stroke-stroke-06 text-center"
                >
                  Whitepaper
                </b>
              </button>
            </RevealOnScrollOnce>
          </div>

          <RevealOnScrollOnce className="hidden_text h-full w-full flex flex-col items-center justify-center">
            <img
              className="h-[300px] w-[300px] relative object-cover z-[1] floating mq1050:hiddenxx"
              loading="lazy"
              alt=""
              src="/imgs/x-logo-sign@2x.png"
            />
          </RevealOnScrollOnce>


        </div>
      </section>

      <section
        className="hidden mq1024:flex flex-row h-[600px] w-[1024] px-[16px] pt-[80px] justify-between items-center
                      mq768:w-max-[736px] 
                      mq400:w-[340px]"
      >
        <div className="flex flex-col items-start">
          <div
            className="w-max-[500px] border-solid border-red border-[0px] pb-[40px] pr-[100px] flex flex-col gap-[32px] justify-center items-start
                     mq768:w-max-[440px] mq768:pr-0 mq768:gap-[24px]"
          >
            <RevealOnScroll className="hidden_text hero_text1">
              Next Generation AI cryptocurrency ecosystem{" "}
            </RevealOnScroll>

            <RevealOnScroll className="hidden_text">
              <snap className="hero_text2_snap"> AlpinX: </snap>
              <snap className="hero_text2">
                {" "}
                Intelligent and Effortless Crypto Experience with AI Companion{" "}
              </snap>
            </RevealOnScroll>

            <RevealOnScroll className="hidden_text hero_text3">
              Dive into the heart of the innovation with AlpinX - your
                    gateway to a seamless and secure crypto trading experience.
                    <br />
                    Our cutting-edge ecosystem and deep AI integration are designed to revolutionize the
                    way you trade, invest, and manage your digital assets.

            </RevealOnScroll>
          </div>

          <RevealOnScroll className="hidden_text flex flex-row justify-center items-center gap-[8px]">
            <button className="hero_btn1">
              <div className="hero_btn1_text" onClick={onAboutTextClick}>
                Explore AlpinX
              </div>
            </button>

            <button className="hero_btn2" onClick={openPdfFile}>
              <div className="hero_btn2_text">Whitepaper</div>
            </button>
          </RevealOnScroll>
        </div>

        <RevealOnScroll
          className="hidden_text w-[250px] h-[250px] border-solid border-red border-[0px] shrink-0
      mq768:w-[200px] mq768:h-[200px]
      mq500:hidden"
        >
          <img
            className="hero_svg w-[250px] h-[250px] shrink-0 mq768:w-[200px] mq768:h-[200px]"
            loading="lazy"
            alt=""
            src="/imgs_add/x logo sign 200x200.svg"
          />
        </RevealOnScroll>
      </section>
    </div>
  );
};

export default Hero;
